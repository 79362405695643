
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { userActions } from "../../Actions";

const DashboardNav = ({active, session}) => {

    const [settings, setSettings] = useState('');
    const [classes, setClasses] = useState('');
    const [game, setGame] = useState('');
    const [scrollTop, setScrollTop] = useState(0);
    const componentRef = useRef(null);

    useEffect(()=> {
        switch (active) {
            case 'settings':
            setSettings('active');
                break;
            case 'classes':
                setClasses('active');
                break;
            case 'game':
                setGame('active');
                break;
            default:    
        }
    })

    const teacherGameView = () => {
       // RegisterStudentAsTeacher({ first_name, last_name, student_id, classroom_code, linked_to_teacher })
    }

    return (
        <Fragment>
            <nav className={'dashboard-nav'} ref={componentRef}>
                <div className={'header'}>IDA GEM</div>
                <div className={'nav'}>
                    <a href='/Dashboard' className={classes}>Dashboard</a>
                    <a className={game} onClick={() => {
                                                    if(window.confirm('Functionality not added yet!') == true) {
                                                        
                                                    }
                                                }}>Game</a>
                    <a href={'/settings'} className={settings}>Settings</a>
                </div>
            </nav>
        </Fragment>
    )
};


function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    RegisterStudent: userActions.RegisterStudent,
};

const connection = connect(mapState, actionCreators)(DashboardNav);
export { connection as DashboardNav };