//--------------------------------------------------
//Classroom Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';

const TravelogueList = ({ key }) => {

    const params = new URLSearchParams(new URL(window.location.href).search);
    const classroom_id = params.get("classroom");
    const student_id = params.get("student");
    const token = localStorage.getItem("token");

    const [content, setContent] = useState(null);
    const [username, setUsername] = useState("");
    const [openQuests, setOpenQuests] = useState([]); //holds whether the quest dropdowns are open or not
    const [openPhases, setOpenPhases] = useState([]); //holds whether the phase dropdowns are open or not

    useEffect(() =>
        GetTravelogue(),
        []);

    function GetTravelogue() {
        userActions.GetStudentTravelogue(classroom_id, student_id, token, (data) => {
            setContent(data.responses[0].travelogue);
            setUsername(data.responses[0].username);
        })
    }

    function displayTravelogue(travelogue) {
        //console.log("travelogue (aka content): ", travelogue);

        if (!travelogue || Object.keys(travelogue).length == 0) {
            return (
                <div className='studentRow'>
                    <span className='bold font-lg grid-col-12'>This {params.get("student") ? 'student' : 'class'} has not yet completed any phases!</span>
                </div>
            )
        }

        return (
            <div>
                {Object.keys(travelogue).map((questKey) => (
                    <div key={questKey} className="Quest">
                        <div className='dropdown' onClick={() => toggleQuest(questKey)}>
                            <h1>Quest {questKey}</h1>
                            {openQuests.includes(questKey) ? <span class="material-symbols-outlined">keyboard_arrow_down</span> : <span class="material-symbols-outlined">keyboard_arrow_right</span>}
                        </div>

                        {openQuests.includes(questKey) && (
                            <div className="phasesContainer">
                                {QuestDetails(travelogue[questKey].answers)}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        )
    }

    function QuestDetails(answers) {
        return (
            <div className='Phase'>
                {Object.keys(answers).map((key) => (
                    <div key={key}>
                        <div className='dropdown'>
                            <h2 onClick={() => togglePhase(key)}>Phase {parseInt(key) +1}</h2>
                            {openPhases.includes(key) ? (
                                <span className="material-symbols-outlined">keyboard_arrow_down</span>
                            ) : (
                                <span className="material-symbols-outlined">keyboard_arrow_right</span>
                            )}
                        </div>

                        {openPhases.includes(key) && (
                            <div className='phaseContent'>
                                {answers[key] !== "" ? (
                                    typeof answers[key] === "string" ? (
                                        <div>
                                            <h3>Notes: </h3>
                                            <p>{answers[key]}</p>
                                        </div>
                                    ) : (
                                        Object.keys(answers[key]).map((subKey) => (
                                            <div key={subKey}>
                                                <h3>{subKey}: </h3>
                                                <p>{answers[key][subKey]}</p>
                                            </div>
                                        ))
                                    )
                                ) : (
                                    <p>Incomplete</p>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        )
    }

    // Function to toggle quest dropdown
    const toggleQuest = (questKey) => {
        setOpenQuests((prev) =>
            prev.includes(questKey) 
        ? prev.filter((quest) => quest !== questKey) 
        : [...prev, questKey]);
    };

    // Function to toggle phase dropdown
    const togglePhase = (phaseKey) => {
        setOpenPhases((prev) =>
            prev.includes(phaseKey)
                ? prev.filter((phase) => phase !== phaseKey)
                : [...prev, phaseKey]);
    };

    return (
        <Fragment>
            <head>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
            </head>
            <div className={'studentView TravelogueView'} key={key}>
                <div className='grid-col-2 headerRow'>
                    <h1 className='Approval-Travelogue-Header'>Travelogue</h1>
                    <h2 className='Approval-Travelogue-Header'>{username}</h2>
                </div>

                <div className='studentContainer' >
                    {displayTravelogue(content)}
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(TravelogueList);
export { connection as TravelogueList };