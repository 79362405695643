//--------------------------------------------------
//Classroom Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';
import { FaArrowsRotate, FaCirclePlus } from "react-icons/fa6";
import placeholder from '../../Images/placeholder.jpg'

const StudentList = ({ Popup, key }) => {

    const id = window.location.href.split('/')[window.location.href.split('/').length - 1];
    console.log("id ", id)
    const token = localStorage.getItem('token');
    const [classContent, setClassContent] = useState({ classroom: {}, students: [] });



    useEffect(() =>
        userActions.GetClassroomContent(id, token, (data) => {
            const { err } = data;
            if (err) {
                window.alert(err)
                return (
                    <Fragment>
                        <div className={'studentView'} key={key}>
                            {err}
                        </div>
                    </Fragment>
                )
            }

            if (data) {
                setClassContent(data);
            }
        }, ''), []);


    const Confirmation = (studentID, name) => {

        Popup(studentID, name);

    }

    const RefreshClassroomCode = (element) => {
        userActions.RefreshClassroomCode(id, token, (data) => {
            element.innerHTML = data;
        })
    }

    return (
        <Fragment>

            <div className={'studentView'} key={key}>
                <div className='grid-col-2 headerRow'>
                    <h1 className='sectionHeader'>{classContent.classroom?.name}: Student List <span className='text-black'>({classContent.students?.length})</span></h1>
                    <h1 className='sectionHeader'><span>Code:</span> <span className='text-black'>{classContent.classroom?.code}</span><FaArrowsRotate className={'refresh-arrows'} onClick={(e) => RefreshClassroomCode(e.target.parentElement.children[1])} /></h1>
                </div>
                <div className='studentContainer'>
                    {classContent?.students?.length > 0 ? classContent.students?.map(index => {
                        return (
                            <div className='studentRow'>
                                <div className='imageContainer'><img src={userActions.GetFile(index.icon) === 'https://idagem.org/api/file/null' ? placeholder : userActions.GetFile(index.icon)}></img> </div>
                                <div className='studentSection'>
                                    <span className='grid-col-2 smaller-student-text'>Username: </span><span className='bold font-lg'>{index.username}</span>
                                    <br/>
                                    <p id='student-id' className='smaller-student-text'>Student ID: {index.student_id}</p>
                                </div>
                                <div className='links'>
                                    <div className='bold font-lg linkElement'>
                                        <a className='classlist-link' href={'/classroom/view/verify?classroom=' + id + '&student=' + index.id}>Approve</a>
                                    </div>
                                    <div className='bold font-lg linkElement'>
                                        <a className='classlist-link' href={'/classroom/view/student?classroom=' + id + '&student=' + index.id}>View Progress</a>
                                    </div>
                                    <div className='bold font-lg linkElement'>
                                        <a className='classlist-link text-red' onClick={() => Confirmation(index.id, index.first_name)}>Remove</a>
                                    </div>
                                </div>
                            </div>
                        )
                    }) :
                        <div className='studentRow'>
                            <span className='bold font-lg grid-col-12'>Invite Students by sharing your class code!</span>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(StudentList);
export { connection as StudentList };