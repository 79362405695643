//--------------------------------------------------
//Rendered Component when needing school list input
//--------------------------------------------------

import React, { Fragment, useState, useEffect } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';


const SchoolList = ({ returnSchool, value }) => {


    const inputField = React.createRef();

    const [schools, setSchools] = useState([]);
    const [newValue, setValue] = useState('');
    useEffect(() => {
        userActions.GetSchoolList('id', (data) => {
            setSchools(data)
        })
        setValue(value);
    }, [value]);

    function hideList(input) {
        if (input.length > 3) {
            inputField.current.setAttribute('list', "schools-list")		
        } else {
            inputField.current.removeAttribute('list')	
        }
    }

    return (
        <Fragment>
            <input className='full-input' list="" placeholder='Enter Your School' value={newValue} onChange={(e) => returnSchool(e.target.value)} onInput={(e) => hideList(e.target.value)} autoComplete='off' ref={inputField}></input>
            <datalist id="schools-list">
                {schools.map(index => {
                    return (
                    <option value={index}></option>
                    )
                })
                }
            </datalist>
        </Fragment>
    )
};


function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    HandleApiRequest: userActions.HandleApiRequest
};

const connection = connect(mapState, actionCreators)(SchoolList);
export { connection as SchoolInput };