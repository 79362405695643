import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import { AiFillSound } from "react-icons/ai";
import { FaPlay, FaPause, FaStop } from "react-icons/fa";

const buttonStyle = {
    position: "absolute",
    height: "50px",
    width: "50px",
}

const TTSButton = ({isActive, utterance}) => {
    const [TTSPlaying, setTTSPlaying] = useState(false);
    const [TTSEnabled, setTTSEnabled] = useState(false);
    return (
        <Fragment>
            {!TTSEnabled ?
                <button 
                style={buttonStyle}
                onClick={() => {
                    console.log(utterance);
                    const speechUtterance = new SpeechSynthesisUtterance(utterance);
                    speechSynthesis.speak(speechUtterance);
                    console.log(speechUtterance);
                    setTTSEnabled(true);
                    setTTSPlaying(true);
                }}
                disabled={!isActive}
                ><AiFillSound color={"black"}/></button>
                :
                <div style={buttonStyle}>
                <button
                    style={buttonStyle}
                    onClick={() => {
                            if(TTSPlaying){
                                speechSynthesis.pause();
                                setTTSPlaying(false);
                            } else {
                                speechSynthesis.resume();
                                setTTSPlaying(true);
                            }
                        }}
                >{TTSPlaying ? <FaPause /> : <FaPlay />}</button>
                <button 
                    style={buttonStyle}
                    onClick={() => {
                        speechSynthesis.cancel();
                        setTTSEnabled(false);
                    }}><FaStop /></button>
                </div>
                }
        </Fragment>
    )
}

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {

};

const connection = connect(mapState, actionCreators)(TTSButton);
export { connection as TTSButton };
