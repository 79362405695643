//--------------------------------------------------
//Rendered Component when opening website for the first time
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';

const Home = ({ session }) => {

    document.title = 'Homepage'
    console.log(session)
    return (
        <Fragment>
            <div class="banner-container home-splash">
                <div className="banner-image" style={{display: 'grid',  backgroundColor: '#000000B0'}}>
                    <div style={{display: 'grid', margin: 'auto', alignSelf: 'center', justifySelf: 'center'}}>
                        <h1 style={{margin: 'auto', alignSelf: 'center', justifySelf: 'center', fontSize: '5rem', fontWeight: 'bold'}}>IdaGem</h1>
                        <p  style={{margin: 'auto', textAlign:'center', alignSelf: 'center', justifySelf: 'center', fontSize: '2rem', fontWeight: '500'}}>Learn about Idaho's vast history as you complete quests and activities found within a game environment!</p>
                    
                        <a href={session.token != "" ? 'game/' : '/register'} style={{margin: '4% auto', alignSelf: 'center', justifySelf: 'center', width: '30%'}}>
                        <button className='register-button' style={{padding: '2%', fontSize: '3em', width: '100%'}}>{session.token != "" ? 'Go To Game' : 'Get Started'}</button>
                    </a>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Home);
export { connection as Home };