//--------------------------------------------------
//Quest Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from "../../Actions";
import { DashboardNav } from "../DashboardComponents/DashboardNav";

const QuestList = ({ }) => {

    const params = new URLSearchParams(new URL(window.location.href).search);
    const classroom_id = params.get("classroom");
    const id = window.location.href.split('/')[window.location.href.split('/').length - 1];
    const token = localStorage.getItem('token');

    const [quests, setQuests] = useState([]);
    const [currentQuest, setCurrentQuest] = useState({ id: 0, name: 'Select a Quest', subname: 'View its Details', duration: '', difficulty: '', description: '', locked: 1 });
    const [currentQuestButton, setCurrentQuestButton] = useState(null);
    const [animatePanel, setAnimation] = useState('animateFromRight');

    const [tempFirstToggle, setTemp] = useState('purple')


    useEffect(() =>
        userActions.GetActiveQuests(token, classroom_id, (data) => {

            let questList = data.quests;
            for (const x in questList) {
                questList[x].locked = data.locked[questList[x].id]
                //old code to make only the first quest unlocked initially
                //See if 0 to toggle
                /*if (data.locked[questList[x].id] == 0) {
                    questList[x].locked = data.locked[questList[x].id]
                }
                //If its not zero, it equals 1 or doesnt exist yet, so it should be 1 (locked)
                else {
                    questList[x].locked = 1;
                }*/
            }
            ToggleAnimation();
            if (questList) { 
                setQuests(questList); 
                setCurrentQuest(questList[0]); 

            }
        }), []);

    const changeQuest = (quest, target) => {

        if(quest.id == currentQuest.id) {
            return;
        }

        setTemp('');

        if(currentQuestButton) {
            currentQuestButton.className = '';
        }
        target.className = 'purple';
        setCurrentQuestButton(target);

        setCurrentQuest(quest);
        ToggleAnimation();
        return 'purple';
    }

    const ToggleAnimation = () => {
        setAnimation('animateFromRight');
        setTimeout(() => {
            setAnimation('');
        }, 750)
    }

    const toggleQuestLock = (quest, target) => {
        //make call to change lock status

        userActions.ToggleLockQuest(token, classroom_id, quest, (data) => {
            if (data) {
                let temp = currentQuest;
                temp.locked = data.lock_status;
                //needs ... so that it is a new array, not reference
                setCurrentQuest({ ...temp });
            }
            else {
                console.log('Could not toggle quest at this time')
            }
        })
    }

    // console.log(quests)

    return (
        <Fragment>
            <div className='main-container page max-height-screen'>

                <DashboardNav />
                <div className={'classlist'}>
                    <h1 className='sectionHeader grid-col-1'>Quest Dashboard <span className='text-black'></span></h1>
                    <div className='questsContainer overflow-x-clip'>
                        <div className='quests'>
                            {quests?.map((quest, index) => {
                                return (
                                    <div className={index == 0 ? tempFirstToggle : ''} onClick={(e) => changeQuest(quest, e.target)} key={'quest ' + quest.id}>
                                        {quest.name}
                                    </div>
                                )
                            })}
                        </div>
                        <div className={`questInfo ${animatePanel}`}>
                            <h1>Quest Information</h1>
                            <div className='questContent'>
                                <h2>{currentQuest.name}</h2>
                                {/* <h3>{currentQuest.subname}</h3> */}
                                <hr></hr>
                                <div className='stat'>
                                    Quest Duration: <span>{currentQuest.duration}</span>
                                </div>
                                <div className='stat'>
                                    Quest Difficulty: <span>{currentQuest.difficulty}</span>
                                </div>
                                <div className='stat'>
                                    Quest Summary: <br></br><span>{currentQuest.description}</span>
                                </div>
                                <div className='stat inline-flex'>
                                    Lock Quest:
                                    <label className="switch">
                                        <input type="checkbox" onChange={(e) => toggleQuestLock(currentQuest.id, e.target)} checked={currentQuest.locked == 1 ? true : false}></input>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(QuestList);
export { connection as QuestList };