//--------------------------------------------------
//Classroom Page for Teachers
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';
import { DashboardNav } from "../DashboardComponents/DashboardNav";
import { SchoolInput } from "../GeneralComponents/schoolList";
import { VerifyEmail } from './VerifyEmail';
import { FaArrowUpFromBracket, FaCheck, FaLock, FaLockOpen, FaXmark } from "react-icons/fa6";
import placeholder from '../../Images/placeholder.jpg'

const Settings = ({ session, EditAccount, DeleteAccount }) => {

    document.title = 'Settings'

    const params = new URLSearchParams(new URL(window.location.href).search);
    const classroom_id = params.get("classroom");
    const student_id = params.get("student");
    const token = localStorage.getItem("token");


    const [content, setContent] = useState(null);
    const [id, setId] = useState(null);
    const [school, setSchool] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [clearance, setClearance] = useState("");
    const [avatar, setAvatar] = useState("");
    const [displayImage, setImage] = useState("");


    useEffect(() =>
        getUserInfo(),
        []);

    const SetDisplayImage = (image, isUrl) => {
        console.log(isUrl)
        if (!isUrl) {
            const reader = new FileReader();

            reader.onload = function (e) {
                setImage(e.target.result);
            };

            reader.readAsDataURL(
                image
            );
        }
        else {
            setImage(image);
        }
    }

    const ChangeImage = (image) => {
        // const form = new FormData();
        // form.append('file', image);
        // userActions.Upload(form, (err, message, data) => {
        //     if(!err) {
        //         setAvatar(data.data);
        //     }
        // })
        setAvatar(image);
        SetDisplayImage(image, false);
    }

    const getUserInfo = () => {
        userActions.GetUserData(token, (data) => {
            if (data) {
                setContent(data);
                setId(data.id);
                setSchool(data.school);
                setEmail(data.email);
                setFirstName(data.first_name);
                setLastName(data.last_name);
                setClearance(data.clearance);
                setAvatar(data.icon);
                if (data.icon) {
                    SetDisplayImage(userActions.GetFile(data.icon), true);
                }
                else {
                    SetDisplayImage(placeholder, true);
                }
            }
        }, '?student=' + student_id)
    }

    return (
        <Fragment>
            <DashboardNav active='settings'/>
            <div className={'settings-view'}>
                <h1 className='sectionHeader'>Account Settings</h1>
                <div className={'panel'}>
                    <div className='user-left'>
                        <section className='change-pfp'>
                            <section className='circle-blue-settings grid-row-2'><img src={displayImage}></img>ICON</section>
                            <h1 className='username align-end margin-l-5'>PROFILE PICTURE</h1>
                            <label className="change-pfp-button margin-l-5">
                                <input type="file" onChange={(e) => ChangeImage(e.target.files[0])}></input>
                                <span className="clickable">Change Profile Picture <FaArrowUpFromBracket /></span>
                            </label>
                        </section>
                        <section className='name-row'>
                            <h1 className='username align-end'>FIRST NAME</h1>
                            <input type="text" className="text-input" placeholder='First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)}></input>
                        </section>
                        <section className='name-row'>
                            <h1 className='username align-end'>LAST NAME</h1>
                            <input type="text" className="text-input" placeholder='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)}></input>
                        </section>
                        <section className='name-row'>
                            <h1 className='username align-end'>SCHOOL</h1>
                            <SchoolInput returnSchool={setSchool} value={school} />
                        </section>
                    </div>

                    <div className='user-right'>
                        <section className='name-row'>
                            <h1 className='username align-end'>EMAIL</h1>
                            <div className='input-with-icon'>
                                <input type="text" className="field" placeholder='Email' value={email} autoComplete='off' onChange={(e) => setEmail(e.target.value)}></input>
                                {clearance == 'unverified' ? <FaXmark color='red' /> : <FaCheck color='green' />}
                            </div>
                            {clearance == 'unverified' ? <VerifyEmail /> : <span>Verified</span>}
                        </section>
                        <section className='name-row'>
                            <h1 className='username align-end'>PASSWORD</h1>
                            <div className='input-with-icon'>
                                <input type="password" className="field" value={password} autoComplete='off' onChange={(e) => setPassword(e.target.value)}></input>
                                <FaLock />
                            </div>
                            <button className='change-button' onClick={() => userActions.reroute("ResetPassword")}>Change Password</button>
                        </section>
                        <section className='buttons'>
                            <button className='purple' onClick={() => EditAccount(token, firstName, lastName, avatar, school, email, password, getUserInfo)}>Apply Changes</button>
                            <button className='red' onClick={() => {
                                                    if(window.confirm('Are you sure you want to delete your account?') == true) {
                                                        DeleteAccount(token, id);
                                                    }
                                                }}>Delete Account</button>
                        </section>
                    </div>

                </div>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    EditAccount: userActions.EditAccount,
    DeleteAccount: userActions.DeleteAccount
};

const connection = connect(mapState, actionCreators)(Settings);
export { connection as Settings };