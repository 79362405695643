//--------------------------------------------------
//Rendered Component for resetting password
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import env from "react-dotenv";

const ResetPassword = ({ session, SendResetEmail, CheckResetCode, ResetPassword }) => {
   
    document.title = 'Reset Password';

    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);

    useEffect(() => {
        if(password === confirmPassword) {
            setPasswordMatch(true)
        } else {
            setPasswordMatch(false)
        }
      }, [password, confirmPassword]);

    const reset = () => {
        if(password === confirmPassword) {
            ResetPassword({accessToken: session.accessToken, password: password})
        } 
    }

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };
    const handleCode = (e) => {
        const uppercaseCode = e.toUpperCase();
        setCode(uppercaseCode)
    }

    return (
        <Fragment>
            <div className='page reset-password'>
                <h1 className='sectionHeader-middle'>Password Reset</h1>
                {session.accessToken ? 
                    <div className='panel'>
                           <div className='email-input'> 
                                <h3>NEW PASSWORD</h3>
                                <input id="password" className='' type='text' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)}></input>
                            </div>
                            <div className='email-input'> 
                                <h3>CONFIRM PASSWORD</h3>
                                <input id="confirmPassword" className='' type='text' placeholder='Confirm Password' value={confirmPassword} onChange={(e) => setconfirmPassword(e.target.value)}></input>
                            </div>
                        
                        {!passwordMatch ? <p className='center text-red'>Passwords do not match.</p> : <></>}

                        <button className='reset-button' onClick={() => reset()}>CHANGE PASSWORD</button>
                    </div>
                    :
                    session.resetSent ? 
                        <div className='panel'>
                    
                               <div className='email-input'> 
                                <h3>CODE</h3>
                                <input
                                id="codeInput"
                                className=''
                                placeholder='000000'
                                type="text"
                                value={code}
                                onChange={(e) => handleCode(e.target.value)}
                            />
                            </div>
                            <p className='center'>Do not refresh this page</p>
                            <button className='reset-button' onClick={() => CheckResetCode({code, email})}>VERIFY</button>
                        </div>
                        :
                        <div className='panel'>
                            <h2>Please enter your email to recieve a verification code.</h2>
                            <div className='email-input'> 
                                <h3>EMAIL</h3>
                                <input id="emailInput" className='' type='text' placeholder='Example@email.com' value={email} onChange={(e) => setEmail(e.target.value)}></input>
                            </div>
                            <button className='reset-button' onClick={() => SendResetEmail({email})}>SEND EMAIL</button>
                        </div>
                        
                }
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
    SendResetEmail: userActions.SendResetEmail,
    CheckResetCode: userActions.CheckResetCode,
    ResetPassword: userActions.ResetPassword,
};

const connection = connect(mapState, actionCreators)(ResetPassword);
export { connection as ResetPassword };