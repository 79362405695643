//--------------------------------------------------
//Root logic for client rendering down from index.js
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { alertActions } from "../Actions";
import { connect } from 'react-redux';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert } from 'react-bootstrap';

import {Login, Register, Settings, DefaultPage, ResetPassword, Account, VerifyEmail, Home, Unity, Dashboard, Classroom, StudentPage, QuestList, QuestForm, Quest, Approval, TravelogueDisplay, Exhibits } from '../Components/UserPages';
import {NavigationBar, PageNotFound} from '../Components/GeneralComponents';
import { QuestTemplate } from '../Components/Activities/Pages/QuestTemplate';
import { StudentDashboard } from '../Components/DashboardComponents/StudentDashboard';
import { ApprovalRounded } from '@mui/icons-material';

const App = ({ alert, session }) => {  
  const { clearance } = session
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  //Defining multiple clearance levels of access in one variable for pages array
  const user_admin = clearance === 'admin' || clearance === 'user';
  const unverified_user_admin = clearance === 'unverified' || clearance === 'user' || clearance === 'admin';
  
  //Pages where specific clearance levels need access
  const pages = [
    { path: '/DefaultPage', component: user_admin ? <DefaultPage/> : <PageNotFound access="denied"/> },
    { path: '/Account', component: unverified_user_admin ? <Account/> : <PageNotFound access="denied"/> },
    { path: '/VerifyEmail', component: clearance === 'unverified' ? <VerifyEmail/> : <PageNotFound access="denied"/> },
  ];

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, [window.location]);

  const lowerCasePath = currentPath.toLowerCase();
  const shouldNotShowHeader = ['/register', '/login', '/ResetPassword'].some(path => lowerCasePath === path.toLowerCase());
  
  return (
    <BrowserRouter>
      {shouldNotShowHeader ? <></> : <NavigationBar />}
      
      {/*Displays alert at the top of the screen*/}
      {alert.message ? <Alert variant={alert.type} dismissible className={`fadeAlert`}>{alert.message}</Alert> : <Fragment></Fragment>}

      {/*Displays loading screen on top of page to disable user input*/}
      {alert.loading ? <div class="loading-container"><div class="loading-text">Loading...</div></div>: <Fragment></Fragment>}

      <Routes>
        {/*Pages where no clearance level is required*/}
        <Route path="/" element={<Home />}/>
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/Settings" element={<Settings />} />
        <Route path="/QuestTemplate" element={<QuestTemplate />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/Classroom/:id" element={<Classroom />} />
        <Route path="/Classroom/View/Student" element={<StudentPage />} />
        <Route path="/Classroom/View/Quests" element={<QuestList />} />
        <Route path="/Classroom/View/Verify" element={<Approval />} />
        <Route path="/Classroom/View/Travelogue" element={<TravelogueDisplay />} />
        <Route path="/Classroom/View/Exhibits" element={<Exhibits />} />
        <Route path="/Quests/Form" element={<QuestForm />} />
        <Route path="/Quests" element={<Quest />} />
        <Route path="/StudentDashboard" element={<StudentDashboard />} />
        <Route path="/approvals" element={<Approval />} />

        {pages.map(({ path, component }) => (
          <Route key={path} path={path} element={component} />
        ))}

        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

function mapState(state) {
  const { alert, session } = state
  return { alert, session };
}

const actionCreators = {
  clearAlerts: alertActions.clear
};

export default connect(mapState, actionCreators)(App);